import { Area } from "./area";
import { ConsultancyCourse } from "./consultancyCourse";
import { Audit } from "./audit";
import { Diet } from "./diet";
import { EquipmentThermal } from "./equipmentThermal";
import { Consultancy } from "./consultancy";
import { ConsultancyProfile } from './consultancyProfile';
import { Calibration } from "./calibration";

export class Workcenter {
    id: number;
    name: string;
    address: string;
    idQuote: number;
    areas: Area[]= [];
    dietDTOs: Diet[] = [];
    courses: ConsultancyCourse[] = [];
    auditDTOs: Audit[] = [];
    equipmentThermal: EquipmentThermal[]=[];
    consultancy: Consultancy;
    consultancyProfiles: ConsultancyProfile[] = [];
    calibrations: Calibration[] = [];
}

