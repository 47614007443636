<mat-card>
    <div fxLayout="column">
        <div fxLayout="row" class="flexwrap" *ngIf="!quote.journal">

            <div fxFlex="30" fxFlex.lt-md="100%" *ngFor="let result of results">
                <div fxLayout="column" fxFlex.lt-md="100%">
                    <mat-card-content>
                        <div class="economic">
                            <div fxLayout="row" class="flexwrap">

                                <div fxFlex="100" fxFlex.lt-md="100%">
                                    <div fxLayout="column" fxFlex.lt-md="100%" >
                                        <div fxLayout="column">
                                            <div fxLayout="row" class="flexwrap">
                                                <b>{{result.name}}</b>
                                            </div>
                                          
                                            <div fxLayout="column" style="margin-left: 10px;" *ngIf = "!quote.journal">
                                                <span *ngIf="result.areas?.length > 0" style="font-weight: 600;">Producción:</span>
                                                <br *ngIf="result.areas?.length > 0">
                                                <div fxFlex="100" *ngIf="result.areas?.length > 0">
                                                    <span>- {{ 'quote.detail.air' | translate }}:</span>
                                                </div>
                                                <div *ngFor="let area of result.areas" fxFlex="100" class="flexwrap"
                                                    fxFlex.lt-md="100%" fxLayout="column">
                                                  
                                                    {{area.name}}: {{area.totalCostTestPerArea| currency:'EUR'}}
                                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                                        {{ 'quote.detail.timeAir' | translate }}: {{area.totalTimePerArea}}m
                                                        {{getHours(area.totalTimePerArea)}}h
                                                    </div>
                                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                                        Jornadas: {{area.totalDays}}D
                                                    </div>
                                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                                        {{ 'quote.detail.AirEconomic' | translate }}: {{area.costProduction| currency:'EUR'}}
                                                    </div>
                                                    <br *ngIf="result.areas?.length > 1">
                                                </div>
                                                <br *ngIf="isThermal">
                                                <div fxFlex="100" fxLayout="column" *ngIf="isThermal && result.totalTimeThermal > 0">
                                                    <div fxFlex="100">
                                                        <span>- Termicos: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta térmicos' }}: {{ result.totalSaleThermal |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Coste térmicos' }}: {{ result.totalCostThermal |
                                                        currency:'EUR'}}
                                                    </div>
        
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Horas térmicos' }}: {{ result.totalTimeThermal }}h
                                                    </div>
                                                </div>
                                                <br *ngIf="isCalibration">
                                                <div fxFlex="100" fxLayout="column" *ngIf="isCalibration && result.sumTimeCalibrations > 0">
                                                    <div fxFlex="100">
                                                        <span>- Calibraciones: </span>
                                                    </div>
                                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isCalibration">
                                                        Venta calibraciones: {{result.sumPriceCalibrations | currency:'EUR'}}
                                                    </div>
                                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isCalibration">
                                                        Coste calibraciones: {{result.sumCostCalibrations | currency:'EUR'}}
                                                    </div>
                                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isCalibration">
                                                        Tiempo total calibraciones: {{result.sumTimeCalibrations}}h
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br *ngIf="quote.idType === 5 || isConsultancy && !quote.journal">
                                        <div *ngIf="quote.idType === 5 || isConsultancy && !quote.journal" fxLayout="column">
                                            
                                            <div *ngIf="result.areas?.length > 0 && isConsultancy"  fxLayout="column">
                                                <div fxFlex="100" >
                                                    <span style="font-weight: 600;">Consultoría: </span>
                                                </div>
                                                <div fxFlex="100" >
                                                    <span >- Por area: </span>
                                                </div>
                                                <div *ngFor="let area of result.areas" fxFlex="100" class="flexwrap" style="margin-left: 10px" fxFlex.lt-md="100%">
                                                    <div fxFlex="100" fxLayout="column">
                                                       <span style="font-weight: 500;">{{area.name}}</span>
                                                        <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isConsultancy">
                                                            Venta: {{area.totalSaleConsultor | currency:'EUR'}}
                                                        </div>
                                                        <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isConsultancy">
                                                            Coste: {{area.totalCostConsultor | currency:'EUR'}}
                                                        </div>
                                                        <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="isConsultancy">
                                                            Tiempo total: {{area.totalTimeConsultor}}h
                                                        </div>
                                                        <br *ngIf="result.areas?.length >= 1">
                                                    </div>
                                                </div>
                                            </div>
                                            <br *ngIf="isThermal && isConsultancy">
                                            <div fxFlex="100" fxLayout="column" *ngIf="isThermal && isConsultancy && result.totalTimeQualificationThermal > 0">
                                                <div>
                                                    <span>- Termicos: </span>
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Venta: {{result.totalSaleQualificationThermal | currency:'EUR'}}
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Coste: {{result.totalCostQualificationThermal | currency:'EUR'}}
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Tiempo total: {{result.totalTimeQualificationThermal}}h
                                                </div>
                                            </div>
                                            <br *ngIf="hasProfile > 0 && isConsultancy">
                                            <div fxFlex="100" fxLayout="column" *ngIf="hasProfile > 0 && isConsultancy">
                                                <div>
                                                    <span>- Perfiles: </span>
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Venta: {{result.totalSaleProfile | currency:'EUR'}}
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Coste: {{result.totalCostProfile| currency:'EUR'}}
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Tiempo total: {{result.totalTimeProfile}}h
                                                </div>
                                            </div>
                                            <br *ngIf="totalCourses > 0 && isConsultancy">
                                            <div fxLayout="row" class="flexwrap" *ngIf="isConsultancy && getCoursesTotal(result.id) > 0">
                                                {{ 'Formación' | translate }}: {{ getCoursesTotal(result.id) | currency:'EUR'}}
                                            </div>
                                            <div fxLayout="row" class="flexwrap" *ngIf="isConsultancy && result.sumAudits > 0">
                                                {{ 'Auditorias' | translate }}: {{ result.sumAudits | currency:'EUR'}}
                                            </div>
                                            <br *ngIf="result.auditDTOs?.length > 0">
                                            <div *ngFor="let audit of result.auditDTOs" fxFlex="100" class="flexwrap"
                                                fxFlex.lt-md="100%">
                                                {{ showAuditName(audit.auditType) | translate }}
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Precio: {{audit.price| currency:'EUR'}}
                                                </div>
                                            </div>
                                        </div>
                                        <br *ngIf="result?.sumDiets">
                                        <div fxLayout="row" class="flexwrap" *ngIf="result?.sumDiets">
                                            <span style="font-weight: 600;">{{ 'Dietas' }}</span>: {{ result.sumDiets | currency:'EUR'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex.lt-md="100%">
            <mat-card-content>
                <div class="economic">
                    <div fxLayout="row" class="flexwrap" fxFlex="100">
                        <mat-card fxFlex="30" fxLayout="column">
                            <div fxLayout="row" class="flexwrap" fxFlex="100">
                                {{ 'Coste total: ' }} <div #divCost> {{quote.totalCost | currency:'EUR'}}</div>
                            </div>
                            <div fxLayout="row" class="flexwrap" fxFlex="100" style="font-weight: 600;">
                                {{ 'Precio de venta total: ' }} <div #divTotalAmount> {{quote.totalAmount | currency:'EUR'}}</div>
                            </div>
                            <div fxLayout="row" class="flexwrap" fxFlex="100" *ngIf="!quote?.scope">
                                {{ 'Procentaje de beneficio: ' }} <div #divTotalgainsPercentaje> {{gainsPercentaje}}</div>%
                            </div>
                        </mat-card>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div>
</mat-card>