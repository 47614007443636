import { Injectable } from '@angular/core';
import { Calibration } from '../model/calibration';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalibrationService {

  private calibrationSubject = new BehaviorSubject<Calibration>(new Calibration());
  private unreadSubject = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public course$ = this.calibrationSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public unread$ = this.unreadSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  getExcelTemplate(): Observable<any> {
    const url = environment.quotit + '/calibration/excel-template';

    return this.http.get(url, { responseType: 'blob' });
  }

  downloadExcelCalibration(idAmbient: number, idExcel: number): Observable<any> {
    const url = environment.quotit + `/calibration/excel/${idExcel}`;
    
    return this.http.get(url, { responseType: 'blob' });
}

  findById(id: number): Observable<any> {
    const url = environment.quotit + `/${id}`;

    return this.http.get<any>(url);
}
}
