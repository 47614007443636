<div class="main-container">
  <form>

    <div class="toolbar-list">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1 class="accent-color">{{ 'quoteList.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'quoteList.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()" color="accent">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <app-quote-list-filter (filterEmitter)="doFilter($event)" class="filter-container" >
    </app-quote-list-filter>

    <div class="table-container">
      <table #quotes mat-table matSort [dataSource]="dataSource" aria-describedby="quote list">
        <!--COLUMNA N OFERTA-->
        <ng-container matColumnDef="quoteNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.quoteNumber' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.quoteNumber }} </td>
        </ng-container>
        <!--COLUMNA VERSION-->
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.version' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ ('V' +  item.numVersion) }} </td>
        </ng-container>
        <!--COLUMNA TIPO DE OFERTA-->
        <ng-container matColumnDef="typeOffer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.typeOffer' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{'quote.type.' + getTypeOfferName(item.idType) | translate }} </td>
        </ng-container>
        <!--COLUMNA CLIENTE-->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.client' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.clientName }} </td>
        </ng-container>
        <ng-container matColumnDef="descriptionProject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.descriptionProject' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.descriptionProject }} </td>
        </ng-container>
        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.contact' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.contactName  }} </td>
        </ng-container>
        <!--COLUMNA COMERCIAL-->
        <ng-container matColumnDef="commercial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.commercial' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.commercialUserFullName}} </td>
        </ng-container>
        <!--COLUMNA ESTADO DE LA OFERTA-->
        <ng-container matColumnDef="statusId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.statusId' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ getStatus(item.idStatus) }} </td>
        </ng-container>
        <!--COLUMNA FECHA DE ENVIO-->
        <ng-container matColumnDef="dateSend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.dateSend' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.dateSend | fixDate}} </td>
        </ng-container>
        <!--COLUMNA FECHA GANADA/PERDIDA-->
        <ng-container matColumnDef="dateWonLost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.dateWonLost' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.dateWonLost | fixDate}} </td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.active' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.active}} </td>
        </ng-container>
        <!--COLUMNA IMPORTE-->
        <ng-container matColumnDef="import">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'quoteList.cols.import' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            <span *ngIf="item?.idTimeZone === 1 || item?.idTimeZone === 2 ">{{ item.totalAmount | currency:'EUR'}}</span>
            <span *ngIf="item?.idTimeZone === 3"> {{ item.totalAmount | currency:'USD'}}</span>
          </td>
        </ng-container>
        <!-- Button download Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'quoteList.cols.actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <button mat-icon-button (click)="downloadPdf(element)" matTooltip="{{ 'button.download' | translate }}">
                <mat-icon>cloud_download</mat-icon>
              </button>
              <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteRow(element.id)" 
                 matTooltip="{{ 'button.delete' | translate }}">
                <mat-icon>speaker_notes_off</mat-icon>
              </button>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <span *ngIf="!hasFiltered" class="prefilterMessage">
        <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
      </span>
    </div>

    <div class="table-footer-container">
      <div class="actions">
       <div style="margin-right: 15px">
          <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color">
            {{ 'button.backToTop' | translate }} </a>
       </div>
        <div fxFlex="15">
          <button mat-raised-button (click)="exportTable()" class="stroked-primary">
            <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
          </button>
        </div>
      </div>
      
      <div class="paginator">
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>